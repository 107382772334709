.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e7e7e7;
  height: 90vh;
}

.recordDivision {
  width: 100%;
  height: 55vh;
}

.btnIcons {
  background: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.btnIcons:disabled {
  cursor: not-allowed !important;
  opacity: 0.7;
}

.audio-react-recorder canvas {
  width: 100%;
  height: 55vh;
}

.buttonBoxRecord {
  margin-top: 2vh;
  width: 100%;
}

.recordBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.buttonDisabled .icon {
  color: gray;
  font-size: 10vw;
}

.buttonDesignOnRec .icon {
  color: #e74c3c;
  font-size: 10vw;
}

.buttonDesign .icon {
  color: black;
  font-size: 10vw;
}

.textDanger {
  color: #e74c3c;
}
