@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo&family=Dosis:wght@500&display=swap");

.dashSend {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 2vh;
  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -1vh;
}

.messageStatus {
  display: flex;
  flex-direction: row;
  width: 98%;
  padding-top: 2vh;
  justify-content: space-between;
}

.sendBox {
  display: flex;
  flex-direction: column;
  padding-block: 12px;
  min-width: 15rem;
  max-width: 360px;
  max-height: 110px;
  background: #43b8db;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  justify-content: space-between;
}

.titleCard {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
}

.statusBoxIcons {
  color: white;
}

.customIcon {
  padding-left: 1vw;
  padding-top: 2vh;
}

.sendInnerBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.desc {
  font-size: 0.6vw;
  color: white;
  margin-right: 0.5vw;
  margin-bottom: 0.5vh;
  font-family: "Arvo", serif;
}

.status {
  color: white;
  font-weight: 700;
  font-family: "Source Sans Pro";
  font-size: 1.3vw;
}

.titleGraphic {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.quantity {
  color: white;
  font-weight: 700;
  font-family: "Source Sans Pro";
  font-size: 2vw;
  text-align: center;
}

.sendWidgetMiddleBoxes {
  padding-top: 5vh;
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.sendMiddleBoxOne {
  width: 80%;
  height: 70vh;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
  margin-right: 1vw;
}

.sendMiddleBoxTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23%;
  height: 70vh;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
}

.graphicTitleSend {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.5vh;
  max-height: 45px;
  margin-bottom: 3vh;
  background-color: #218da8;
  border-radius: 5px 5px 0px 0px;
}

.graphicTitleSend span {
  margin-left: 2vh;
  font-family: "Source Sans Pro";
  color: white;
}

.widgetBigBox {
  padding-top: 5vh;
  width: 95%;
}

.bigBox {
  width: 100%;
  min-height: 30vh;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 5vh;
}

.bigBox #area {
  height: 100%;
  width: 100%;
}

.bigBox a {
  margin-top: 1vh;
  margin-left: 2vh;
  margin-bottom: 1vh;
  font-family: "Source Sans Pro";
  font-weight: 300;
  color: white;
}

@media only screen and (max-width: 1300px) {
  .dashSend {
    display: flex;
    flex-direction: column;
    padding-left: 9vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    margin-top: unset !important;
  }
  .sendBox {
    min-width: 12rem;
  }
  .titleCard {
    font-size: 18px;
  }
  .titleGraphic {
    font-size: 17px;
  }
}
