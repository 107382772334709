.blacklisting .textScope textarea {
  width: calc(12px + 30vw);
  height: 10vh;
  max-width: 500px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
  font-size: 1em;
}
