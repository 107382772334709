.timed {
  margin-left: 2vw;
}

.timed .slBottomBox {
  margin-top: 1vh;
  width: 100%;
  height: 100vh;
  max-height: 700px;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.scheduledBottombox {
  margin-top: 2vh;
  width: 100%;
  height: 80vh;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.centerID {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerInput {
  max-width: 275px;
}
