.filter-container {
  background: #2980b9;
  padding: 4px 8px;
  border-bottom: 1px solid #fff;
}
p {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 14px;
}

.btn-no-borders {
  display: flex;
  align-items: center;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: filter 1s;
}

.btn-no-borders:hover {
  filter: brightness(80%);
}

.search-container {
  display: flex;
  align-items: center;
}

.input-search {
  border-radius: 4px 0px 0px 4px;
  border: none;
  padding: 8px;
  box-shadow: none;

  max-width: 30%;
}

.input-search:focus {
  border: none;
  box-shadow: none;
  outline: 0;
}

.icon-close {
  font-size: 20px;
}

.btn-search {
  border: none;
  border-radius: 0px 4px 4px 0px;
  padding: 8px;
  color: #fff;
  background: #8cc6d0;
  cursor: pointer;
  transition: filter 1s;
}
.btn-search:hover {
  filter: brightness(80%);
}
