@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.hibernation {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 2vh;
  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -1vh;
}

.title {
  font-size: calc(12px + 1.2vw);
}

.hibernation .rightToolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hibernation .msgToolBar {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  align-items: center;
}

.hibernation .slBottomBox {
  margin-top: 2vh;
  width: 100%;
  height: 80vh;
  max-height: 800px;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.container {
  width: 100%;
  margin: 12px;
}

.hiberCall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.hiberCall form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.hibernationField {
  /* height: 10vh; */
  width: 100%;
  /* max-height: 100px; */
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hibernationAlign {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
}

.labeling {
  font-size: calc(6px + 0.4vw);
}

.hibernationField input[type="text"] {
  width: 80%;
  height: 5vh;
  /* max-height: 50px; */

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.boxContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.boxContent2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.centerDrop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wraping {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.selectTimer {
  width: 80%;
  height: 5vh;
  max-height: 50px;
  font-size: calc(4px + 0.7vw);
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.selectWrap {
  width: 100%;
  height: 5vh;
  min-width: 400px;
  max-height: 50px;
  font-size: calc(4px + 0.7vw);
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.spacingButton {
  padding: 10px;
}

.enabling {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 80%;
}

.checking {
  margin-right: 1vw;
  display: none;
}

.toggleWrap svg {
  font-size: 3vw;
  margin-left: 1vw;
}

@media only screen and (max-width: 1300px) {
  .hibernation {
    display: flex;
    flex-direction: column;
    padding-left: 10vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }
}
