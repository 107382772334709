/* hsm screen */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.hiberCall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modalScreen {
  width: 600px !important;
  position: relative;
}
.maxSelectInput {
  max-width: 475px !important;
}
.maxInput {
  max-width: 460px;
}

.modalScreen .entireModal {
  width: 100%;
  padding-left: 48px;
}

.modalScreen .attachButton .MuiSvgIcon-root {
  color: white;
}

.modalScreen .onScreenTXT {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalScreen .theBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.modalScreen .inner__title {
  font-weight: 300;
  font-family: "Source Sans Pro";
  font-size: calc(12px + 3vw);
}

.modalScreen .credentials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.modalScreen .insertCredentials {
  display: flex;
  flex-direction: column;
}

.modalScreen .onScreenTXT .titleAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1vh;
  width: 80%;
}

.modalScreen .designedList {
  width: 80%;
  height: 5vh;
  max-height: 50px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.modalScreen .designedMini {
  height: 5vh;
  max-height: 60px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 4vh;
  font-size: 14px;
}

.modalScreen .buttonSight {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 33vw;
}

.modalScreen .checkerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
}

.modalScreen .checkerDiv input[type="checkbox"] {
  display: none;
}

.modalScreen .checkerDiv label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modalScreen .paramsZone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paramSendList {
  max-width: 600px;
  width: 100%;
}

.modalScreen .paramSendList .titleAlign {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
}

.tableContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
