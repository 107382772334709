.MuiDataGrid-main {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.MuiDataGrid-columnHeaders {
  background-color: #218da8 !important;
  color: white;
}

.MuiDataGrid-footerContainer {
  background-color: #218da8 !important;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.MuiDataGrid-columnSeparator .MuiSvgIcon-root {
  visibility: hidden;
}
.text-center {
  text-align: center;
  width: 100%;
}
.inline_Alt {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 8vw;
}
.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  color: white;
}

.MuiDataGrid-columnHeaderTitleContainer .MuiSvgIcon-root {
  color: white;
}

.MuiTablePagination-actions .MuiSvgIcon-root {
  color: white;
}

.MuiInputBase-root .MuiSvgIcon-root {
  color: white;
}

.MuiDataGrid-virtualScroller {
  background-color: white;
}

.MuiInputBase-root {
  color: white;
}

.MuiDataGrid-toolbarContainer {
  background-color: #218da8 !important;
  border-radius: 7px 7px 0px 0px;
  color: white;
  padding-right: 2vw;
  padding-left: 3vh;
  justify-content: space-between;
}

.MuiDataGrid-toolbarContainer .MuiButton-root {
  color: white;
  text-decoration: none;
}

.MuiDataGrid-menuIconButton {
  visibility: hidden;
}

.MuiButton-root .MuiTouchRipple-root {
  border: #8cc6d0;
}

.summStyle {
  background-color: #218da8;
}

.rowAlign {
  align-items: center;
  justify-content: center;
  display: flex;
}

.false {
  color: "#E74C3C";
}

.cellArrangement span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.btn_DEL {
  border: none;
  background-color: #e74c3c;
  border-radius: 6px;
  padding: 0.8vh 0.5vw 0.8vh 0.5vw;
  cursor: pointer;
  transition: all 0.7s;
  color: white;
}

.btn_DEL:hover {
  border: none;
  background-color: #ad392c;
  border-radius: 6px;
  padding: 0.8vh 0.5vw 0.8vh 0.5vw;
  cursor: pointer;
}

.btn_EDIT {
  border: none;
  background-color: #1abc9c;
  border-radius: 6px;
  padding: 0.8vh 0.5vw 0.8vh 0.5vw;
  cursor: pointer;
  transition: all 0.7s;
  color: white;
}

.btn_EDIT:hover {
  background-color: #16a085;
}

.playBtn {
  border-radius: 6px;
  cursor: pointer;
  width: calc(30px + 22vw);
  height: calc(8px + 5vh);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.4s;
  background-color: #1abc9c;
  color: white;
}

.playBtn:hover {
  background-color: #16a085;
}

.linkHistory {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: calc(8px + 1.3vw);
  text-decoration: none;
}

.autoResize a img {
  height: auto;
  width: auto;
  max-height: 128px;
  max-width: 128px;
}

pre {
  margin: 0em;
}

.break-line {
  max-width: 90%;
  word-break: break-all;
  white-space: normal;
  font-size: 14px;
}

.playBtnInner {
  border-radius: 6px;
  cursor: pointer;
  width: calc(8px + 7.5vw);
  height: calc(8px + 3.5vh);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.4s;
  background-color: #1abc9c;
  color: white;
}

.playBtnInner:hover {
  background-color: #16a085;
}

.playBtnInner a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: calc(8px + 0.5vw);
  text-decoration: none;
}

/*-------------------- Filter Customization ------------------------*/

.MuiDataGrid-filterForm .MuiSvgIcon-root {
  color: gray;
}

.MuiDataGrid-panelWrapper {
  width: 43vw;
  min-width: 300px;
}

.MuiDataGrid-panelContent {
  width: 100%;
}

.MuiDataGrid-panelContent .MuiButtonBase-root {
  min-width: 30px;
  max-width: 30px;
}

.MuiFormControl-root .MuiDataGrid-filterFormDeleteIcon {
  width: 8vw;
}

.MuiFormControl-root .MuiTextField-root {
  width: 16vw;
  min-width: 90px;
}

.MuiFormControl-root .MuiDataGrid-filterFormValueInput {
  width: 12vw;
  min-width: 90px;
}

.MuiDataGrid-filterFormOperatorInput {
  width: 16vw;
  min-width: 90px;
}

.MuiDataGrid-panelContent::-webkit-scrollbar {
  height: 10px; /* width of the entire scrollbar */
}

/*----------------------------------------------------------------*/

::-webkit-scrollbar {
  width: 18px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #2193b0; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid white; /* creates padding around scroll thumb */
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.codeAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.codeAlign label {
  margin-bottom: 1vh;
}

.codeStyle {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 1vh;
}

.codeStyle input {
  width: 100%;
  height: 5vh;
  max-height: 50px;
  max-width: 600px;
  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 1rem;
}

.brokerSizer {
  width: 7vw;
  height: 5vh;
}

.list-service-flow {
  font-size: 16px !important;
  color: #000 !important;
}
