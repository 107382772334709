.cdrLog {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 3.5vh;
  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -1vh;
}

.containerInput {
  max-width: 215px;
}

.containerCdrPageHeader {
  max-width: 250px;
}

.containerModal {
  width: 60%;
  margin: 0 auto;
}

.dwnFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1vh;
}

.cdrBottomBox {
  margin-top: 2vh;
  width: 98%;
  height: 80vh;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.MuiInput-input {
  color: black;
}
.materialStyle {
  height: 100% !important;
  width: 100% !important;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.linking:link {
  text-decoration: none;
}

.linking:visited {
  text-decoration: none;
}

.linking:hover {
  text-decoration: none;
}

.linking:active {
  text-decoration: none;
}

@media only screen and (max-width: 1300px) {
  .cdrLog {
    display: flex;
    flex-direction: column;
    padding-left: 10vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }
  .containerInput {
    max-width: 175px;
  }

  .containerCdrPageHeader {
    max-width: 250px;
  }
}
