@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap");

.dash {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 2vh;

  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -1vh;
}

.textCenter {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin: 0;
}

.widgetMiddleBoxes {
  margin-top: 3vh;
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 87vh;
}

.leftMiddleBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.rightMiddleBoxes {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
}

.underBoxes {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 13vh;
  max-height: 130px;
  justify-content: space-between;
  margin-top: -2vh;
  margin-bottom: 1.5rem;
}

.titleUnderBoxes {
  font-weight: 600;
  padding-block: 24px;
}

.statusBox {
  margin-top: 2vh;
  width: 48%;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}

.statusBox .statusTitle {
  height: 30%;
  display: flex;
  background-color: #218da8;
  border-radius: 5px 5px 0px 0px;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
}

.statusBox .statusNumber {
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 2vw;
}

.graphicTitle {
  display: flex;
  flex-direction: row;
  padding-block: 8px;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  background-color: #218da8;
  justify-content: center;
  color: white;
  font-weight: 600;
}

.middleBox a {
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-family: "Source Sans Pro";
  color: black;
  font-style: normal;
  font-weight: normal;
}

.midTermBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 74%;
  height: 100%;
  /* max-height: 750px; */
}

.longBox {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 49%;
  max-height: 490px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 12pt;
  font: bold;
  width: 75%;
}

.footer a {
  padding-bottom: -20px;
}

.graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40%;
  margin-bottom: 2vh;
}

.graphAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.miniTitle {
  margin-top: 3vh;
  font-weight: 600 !important;
}

@media only screen and (max-width: 1300px) {
  .dash {
    display: flex;
    flex-direction: column;
    padding-left: 10vw;
    padding-top: 3vh;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }
  .titleUnderBoxes {
    font-size: 12px;
  }
  .miniTitle {
    font-size: 14px;
    margin-top: 2.5rem;
  }

  .textCenter {
    font-size: 26px;
  }
}
