.containerModal {
  width: 100%;
  margin: 0 auto;
}

.hibernationField input[type="date"] {
  width: 90%;
  height: 5vh;
  max-height: 50px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;

  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.allDayChecked {
  background-color: #e74c3c;
  color: white;
  margin-top: 1vh;
  border-radius: 6px;
  width: 80%;
  border-color: #2980b9;
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  user-select: none;
}

.allDayUnchecked {
  background-color: #1abc9c;
  color: white;
  margin-top: 1vh;
  border-radius: 6px;
  width: 80%;
  border-color: rgba(41, 128, 185, 0.25);
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  user-select: none;
}

.specific {
  visibility: hidden;
  position: fixed;
}

.distribute {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
}

.inputSpacement {
  display: flex;
  width: 45%;
  flex-direction: column;
}

.distribute input[type="time"] {
  width: 100%;
  height: 5vh;
  max-height: 50px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;

  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.uraSelection {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 80%;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.urasField {
  height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkInternal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2px;
}

.weekBox {
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 2vh;

  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.scheduleField {
  /* height: 10vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* ---------------------------- */
.weeklySpace {
  height: 10vh;
  width: 100%;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.weekLabels {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
}

.holydayChecked {
  background-color: #e74c3c;
  color: white;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border-radius: 6px;
  height: 5vh;
  width: 80%;
  border-color: #2980b9;
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  user-select: none;
}

.holydayUnchecked {
  background-color: #1abc9c;
  color: white;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border-radius: 6px;
  height: 5vh;
  width: 80%;
  border-color: rgba(41, 128, 185, 0.25);
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  user-select: none;
}

.holydayFont {
  margin-left: 1svh;
  font-size: large;
}
