@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.containerWrap {
  width: 95%;
  margin: 0 auto;
}

.addIcon {
  background: #0748fb;
  border-radius: 8px;
}

.containerEditor {
  width: 18vw;
  margin-left: 1rem;
}

.formTabs {
  border: 1px solid rgba(41, 128, 185, 0.25);
  padding-inline: 2rem;
  background: hsla(0, 0%, 77%, 0.1);
  border-radius: 5px;
  min-height: 38vh;
  max-height: 40vh;
}

.send {
  margin-right: 1vh;
}

.tabulacoes ul {
  list-style: none;
  padding-inline-start: 1em;
  margin: 0px;
}

.tabulacoes li {
  text-decoration: none;
  color: #000;
  font-size: 1vw;
}

/* test */
.tabulacoes li .alinhamento {
  border: 1px solid #ddd;
  padding: 5px;
  color: #888;
  text-decoration: none;
}

.tabulacoes li:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -20px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 20px;
  height: 15px;
}

.tabulacoes li:after {
  position: absolute;
  content: "";
  top: 5px;
  left: -20px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  width: 20px;
  height: 100%;
}

.tabulacoes li {
  list-style-type: none;
  margin: 10px 0 10px 10px;
  position: relative;
}

/* ---------------- */
.tabulacoes svg {
  cursor: pointer;
}

.tabulacoes .alinhamento {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabulacoes li span {
  padding: 3px;
}

.tabulacoes {
  min-width: 30vw;
  min-height: 35vh;
  max-width: 36vw;
  max-height: 35vh;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid rgba(41, 128, 185, 0.25);
  padding: 1em;
  background: hsla(0, 0%, 77%, 0.1);
  border-radius: 5px;
}

.cadastroTabs {
  min-width: 36vw;
  min-height: 35vh;
  max-width: 36vw;
  max-height: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid rgba(41, 128, 185, 0.25);
  padding: 1em;
  background: hsla(0, 0%, 77%, 0.1);
  border-radius: 5px;
}

.cadastroTabs form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tabulacoes li span.select {
  cursor: pointer;
  transition: all 0.4s ease;
}

.tabulacoes li span.select:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.tabulacoes li span.active {
  text-decoration: underline;
  text-decoration-color: #16a085;
  text-decoration-thickness: 2px;
}

.pad {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 79vw;
}

.addIconStyle {
  background-color: #007bff;
  border-radius: 50%;
  margin-bottom: 0.4vh;
  transition: all 0.7s;
}

.addIconStyle:hover {
  filter: brightness(80%);
}

.addIconStyle2 {
  background-color: #007bff;
  border-radius: 3px 0px 0px 3px;
  transition: all 0.7s;
  margin-left: 1vh;
}

.addIconStyle2:hover {
  filter: brightness(80%);
}

.delIconStyle {
  background-color: #e74c3c;
  border-radius: 0px 3px 3px 0px;
  transition: all 0.7s;
}

.delIconStyle:hover {
  filter: brightness(80%);
}

.slash {
  font-size: 2vw;
}

@media only screen and (max-width: 1300px) {
  .Tabs {
    display: flex;
    flex-direction: column;
    padding-left: 2vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }
}
