@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.history {
  display: flex;
  flex-direction: column;
  padding-left: 12.5vw;
  padding-top: 2vh;
  font-weight: 300;
  font-family: "Source Sans Pro";
  font-size: calc(12px + 1.2vw);
  margin-top: -8vh;
}
.toolBarHistory {
  font-size: 1.5vw;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.7vw;
}

/* .searchFunction label {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: calc(6px + 0.8vw);
  margin-right: 1vh;
} */

/* .searchFunction .numberSelect {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: calc(6px + 0.8vw);
  margin-right: 1vh;
  border-radius: 7px;
  height: 4.6vh;
  width: 12vw;
  max-height: 50px;
  max-width: 215px;
  margin-right: 2vh;
  padding-right: 10px;
  padding-left: 10px;
  border: none;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
} */

.inputContainer {
  width: 180px;
}

/* .searchFunction .countrySelect {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: calc(6px + 0.8vw);
  border-radius: 7px;
  height: 4.6vh;
  width: calc(12px + 5vw);
  max-height: 50px;
  max-width: 75px;
  margin-right: 0.5vw;
  padding-right: 10px;
  padding-left: 10px;
  border: none;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.searchFunction select:hover {
  transition: all 0.5s;
} */

/* .searchFunction input[type="text"] {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: calc(6px + 0.8vw);
  margin-right: 1vh;
  border-radius: 7px;
  height: 4.6vh;
  width: calc(12px + 9vw);
  max-height: 50px;
  max-width: 215px;
  margin-right: 2vh;
  padding-right: 10px;
  padding-left: 10px;
  transition: ease-out 0.5s;
} */

/* .historyToolBar {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  align-items: center;
}

.historyToolBar strong {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 1vw;
  color: white;
  padding: 10px;
  border-radius: 7px;
  transition: all 0.5s;
  cursor: pointer;
}

.historyToolBar strong:hover {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 1vw;
  color: white;
  opacity: 0.5;
  padding: 10px;
  border-radius: 7px;
} */

.bottomBoxHistory {
  margin-top: 2vh;
  width: 100%;
  height: 80vh;
  max-height: 800px;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

@media only screen and (max-width: 1300px) {
  .history {
    display: flex;
    flex-direction: column;
    padding-left: 2vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }
}
