.gridScreen {
    display: grid;
    grid-template-rows: 50px 1fr 75px;
}

.verifyFields {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.verifyFields .basicInput {
    width: 90%;
    height: 5vh;
    max-height: 50px;

    background: rgba(196, 196, 196, 0.1);
    border: 1px solid rgba(41, 128, 185, 0.25);
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 1vh;
    margin-bottom: 2vh;
}

.verifyField input:focus{
    outline: none;
}

/* .verifyField {
    height: 10vh;
    width: 100%;
    max-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */

.verifyField{
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    justify-items: center;
}

.verifyFieldsAlign {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
}

.labeling {
    font-size: calc(6px + 0.5vw);
}

.verifiedInput{
    width: 90%;
    height: 5vh;
    max-height: 50px;

    background: rgba(196, 196, 196, 0.1);
    border: 2px solid rgba(41, 128, 185, 0.25);
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 1vh;
    margin-bottom: 2vh;
    border-color: #1abc9c;
    transition: border 0.5s ease;
}

.deniedInput{
    width: 90%;
    height: 5vh;
    max-height: 50px;

    background: rgba(196, 196, 196, 0.1);
    border: 2px solid rgba(41, 128, 185, 0.25);
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 1vh;
    margin-bottom: 2vh;
    border-color: #e74c3c;
    transition: border 0.5s ease;
}

.lineBreak{
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
}

.verifyFields .notVerified {
    width: 95%;
    height: 5vh;
    max-height: 50px;

    background: rgba(196, 196, 196, 0.1);
    border: 1px solid rgba(41, 128, 185, 0.25);
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 1vh;
    margin-bottom: 2vh;
    margin-left: 1vh;
}

.notVerifiedAlign {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 95%;
    margin-left: 1vh;
}

.warrantyForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1vh;
    margin-bottom: 2vh;
}

.alert{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}