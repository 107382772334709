/* hsm screen */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.hsmScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.timerContainer {
  max-width: 200px;
}

.sizeTextArea {
  width: 95%;
}

.formSize {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.toggleWrap {
  cursor: pointer;
  margin-top: 0.4rem;
}

.parametersContainer {
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  margin-top: 12px;
}
.containerInputParams {
  max-width: 250px;
  margin-right: 24px;
}

.onScreenHSM {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hsmScreen .theBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin: 4vh 2vw 2vh 2vw;
  padding: 0px 35px 15px 35px;
  width: 80%;
  max-width: 550px;
  overflow: hidden;
}

.inner__title {
  font-weight: 300;
  font-family: "Source Sans Pro";
  font-size: calc(12px + 3vw);
}

.credentials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.insertCredentials {
  display: flex;
  flex-direction: column;
}

.selectionNumbers {
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.labelAlign {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1vh;
}

.msgText {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  margin-bottom: 50px;

  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.designed {
  width: 100%;
  height: 5vh;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 1vh;
  font-size: 14px;
}

.designedMini {
  height: 5vh;
  max-height: 60px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 4vh;
  margin-right: 2vh;
  font-size: 14px;
}

.buttonSight {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 33vw;
}

.checkerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-top: 2vh; */
}

.checkerDiv input[type="checkbox"] {
  display: none;
}

.checkerDiv label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.paramStyle {
  width: 100%;
  height: 5vh;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 1vh;
  font-size: 14px;
}

.margin {
  margin-top: 1vh;
  text-decoration: none;
}

.classicAlign {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-left: 4vw;
}

.btnAlign {
  display: flex;
  align-items: center;
}

.btnStylingBlocked {
  align-items: center;
  background-color: #95a5a6;
  border: none;
  border-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 1rem;
  height: 4vh;
  justify-content: center;
  max-height: 60px;
  max-width: 200px;
  transition: all 0.7s;
}

.saveBtnStyling {
  max-height: 60px;
  max-width: 200px;
  height: 4vh;
  font-size: 1rem;
  background-color: #1abc9c;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.7s;
}

.blockedBtnStyling {
  max-height: 60px;
  max-width: 200px;
  height: 4vh;
  font-size: 1rem;
  background-color: #95a5a6;
  color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.7s;
}

.saveBtnStyling:hover {
  background-color: #0f6c5a;
}
