.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
  background-color: white;
  border-radius: 6px;
  margin-top: 2vh;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #218da8;
  border-radius: 6px 6px 0px 0px;
  width: 100%;
  height: 2.8rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.textTitle {
  display: block;
  color: white;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 700;
}

.interpolax .schedule,
.holidays {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  width: 80%;
}

.alignSelecting {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.alignSelecting button {
  margin-bottom: 1vh;
  margin-left: 0.5vw;
}

.cardAlterations {
  margin-bottom: 1vh;
}

@media screen and (max-width: 1300px) {
  .cardContainer {
    width: 25vw;
  }
}
