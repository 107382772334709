.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 7.8vh;
  background-color: white;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  width: 11vh;
}

.collapser{
  display: none;
}
.slidedCollapser{
  display: none;
}

.settings img {
  height: 33px;
  width: 33px;
  padding-top: 2vh;
  padding-right: 1vw;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;
}

.settings img:hover {
  opacity: 0.5;
}

@media only screen and (max-Width: 1300px)
{
  .collapser{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.7s;
    margin-left: 245px;
    height: 5vh;
    width: 5vw;
    cursor: pointer;
  }

  .collapser:hover{
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .header {
    width: unset !important;
  }
}
@media only screen and (max-Width: 1300px)
{
  .slidedCollapser{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s;
    margin-left: 205px;
    margin-top: 1vh;
    height: 50px;
    width: 50px;
    cursor: pointer;
  }

  .slidedCollapser:hover{
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .collapser{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s;
    margin-left: 2vw;
    margin-top: 1vh;
    height: 5vh;
    width: 5vw;
    cursor: pointer;
  }
}