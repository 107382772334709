@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.summarize {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 2vh;
  font-weight: 300;
  font-family: "Source Sans Pro";
  font-size: 1.5vw;
}

.searchFunction {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fontMinor {
  font-size: 26px !important;
  font-weight: 600;
}

.containerInputHeader {
  max-width: 350px;
}

.containerSelectHeader {
  width: 250px;
}

.containerTimerHeader {
  max-width: 222px;
}

::-webkit-calendar-picker-indicator {
  border-radius: 7px;
  cursor: pointer;
  padding: 5px;
}

.sumBottomBox {
  margin-top: 2vh;
  width: 99%;
  height: 78vh;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.MuiInput-input {
  color: black;
}

.materialStyle {
  height: 100% !important;
  width: 100% !important;
}

.msgAdjust {
  white-space: pre-line;
}

.filter {
  flex-wrap: wrap;
}

.filter fieldset {
  border: none;
}

@media only screen and (max-width: 1300px) {
  .summarize {
    display: flex;
    flex-direction: column;
    padding-left: 10vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }

  .containerSelectHeader {
    max-width: 175px;
  }
  .containerTimerHeader {
    max-width: 185px;
  }
  .containerInputHeader {
    max-width: 175px;
  }
}
