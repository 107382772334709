.cloudWords {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 2vh;

  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -1vh;
}

.cloudBox {
  width: 98%;
  height: 82vh;
  max-height: 800px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
}

.cloudTitle {
  display: flex;
  flex-direction: row;
  margin-top: 2vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-radius: 5px 5px 0px 0px;
  width: 98%;
  background-color: #218da8;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
}

.cloudTitle span {
  margin-left: 1vw;
}

.containerInputHeader {
  max-width: 300px;
}

.containerSelectHeader {
  width: 255px;
}

.containerTimerHeader {
  max-width: 205px;
}

@media screen and (max-width: 1300px) {
  .cloudWords {
    padding-left: 10vw;
  }
  .cloudBox {
    height: 78vh;
  }

  .cloudTitle {
    font-size: 16px;
  }
}
