.weeklySpace {
  height: 10vh;
  width: 100%;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.weekLabels {
  text-align: center;
}

.checked {
  background-color: #00dabd;
  padding-inline: 22px;
  padding-block: 16px;
  width: 100%;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: filter 1s;
  text-align: center;
  margin-left: 3vw;
}

.checked:hover {
  filter: brightness(90%);
}

.unchecked {
  background-color: #f02b3f;
  padding-inline: 22px;
  padding-block: 16px;
  width: 100%;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: filter 1s;
  text-align: center;
  margin-left: 3vw;
}

.unchecked:hover {
  filter: brightness(90%);
}
