.sidebarContainer {
  width: 11vw !important;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999999 !important;
}
.sidebarContainer::-webkit-scrollbar {
  display: none;
}

.subMenuFontSize {
  font-size: 14px !important;
}

.buttonMenu {
  background: transparent;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  transition: filter 1s;
}

.buttonMenu:hover {
  filter: brightness(80%);
}

.img {
  z-index: 9999999 !important;
  width: 55px;
  height: auto;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: all ease-in-out 0.4s;
}

.imgV {
  width: 175px;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  transition: all ease-in-out 0.4s;
}

.containerLogoOpen {
  padding-bottom: 70px;
  margin-top: -20px;
}
