.graphicTitle {
  width: 100%;
  padding-block: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #218da8;
  border-radius: 8px 8px 0 0;
  color: #fff;
}

.fullBox {
  width: 98%;
  height: 80vh;
}

.graphicZone {
  height: 70vh;
  background-color: #fff;
  /* border-radius: 0 0 8px 8px; */
}

.internalFooter {
  background-color: #218da8;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
  color: #fff;
  border-radius: 0 0 8px 8px;
}
