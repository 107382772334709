.monitorTitle {
  display: flex !important;
  justify-content: start !important;
}
.titleMonitor {
  text-align: start !important;
  font-weight: 500;
  font-size: 32px;
}

.monitorContainer {
  max-width: 80vw;
  margin-inline: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 1180px) {
  .monitorContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 991px) {
  .monitorContainer {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto;
    max-width: 100%;
  }
}
