.blacklisting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3vh;
}

.textScope {
  width: 95%;
}

.blacklisting form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerBtn{
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
