.workFlowTitle {
  display: flex;
  margin-top: 2vh;
  flex-direction: row;
  padding-bottom: 1vh;
  padding-top: 1vh;
  border-radius: 5px 5px 0px 0px;
  width: 98%;
  background-color: #218da8;
  align-items: center;
  justify-content: center;
  color: white;
}

.workFlow_Title span {
  margin-left: 1vw;
}

.workFlowBoard {
  background-color: white;
  overflow: auto;
  width: 98%;
  height: 75vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.workFlow_toolbar {
  display: flex;
  flex-direction: row;
  width: 99%;
  justify-content: space-between;
  align-items: center;
}

.workFlow_toolbar_period {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  align-items: center;
}

.workFlow {
  padding: 2rem;
}

.workFlowFooter {
  display: flex;
  flex-direction: row;
  height: 2rem;
  border-radius: 0px 0px 5px 5px;
  width: 98%;
  background-color: #218da8;
  align-items: center;
  justify-content: center;
  color: white;
}

.containerSelect {
  width: 275px;
}

.containerInput {
  width: 225px;
}
